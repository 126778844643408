<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :newPage="false" :moduleapi="`${this.baseApi}/approval`" title="Approval"
                :columns="columns" :formOptions="formOptions" :approval="true" popUpCompnent="approvalStatic" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
import axios from '@/components/axios'

export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'Software',
          field: 'SoftwareName',
          searchField:'software',
          type: 'dropdown',
          url: 'getsoftwares',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Software'
          }
        },
        {
          label: 'Module',
          field: 'modulesName',
          searchField: 'modules',
          type: 'dropdown',
          url: 'getmodules',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Module'
          }
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Enable/Disable',
          field: 'active',
          toggle:true,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Approval' : 'Add Approval'}`,
        submitRouterPath: '/setup/approval',
        usersDropdown: true,
        gridForm: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/approval`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'Module Name',
            name: 'modulename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'modules',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          }

        ]
      }
    }
  },
  mounted () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  },
  beforeMount () {
    // this.getDropdowns()
  },
  methods: {
    async getDropdowns () {
      const accessToken = localStorage.getItem('accessToken')
      const baseApi = process.env.VUE_APP_APIENDPOINT
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }
          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)

              this.form[item.name] = data[item.name]
              if (item.name == item.dropdownname) {
                item.options.map((user) => {
                  if (user.id == this.form[item.dropdownname]) {
                    item.item = user.name
                  }
                })
              }
            })
            .catch((error) => console.log(error, 'error'))
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
